function ieFix() {

    // Check if IE 5.5 - 10
    let IE = (function () {
        "use strict";

        let ret, isTheBrowser,
            actualVersion,
            jscriptMap, jscriptVersion;

        isTheBrowser = false;
        jscriptMap = {
            "5.5": "5.5",
            "5.6": "6",
            "5.7": "7",
            "5.8": "8",
            "9": "9",
            "10": "10"
        };
        jscriptVersion = new Function("/*@cc_on return @_jscript_version; @*/")();

        if (jscriptVersion !== undefined) {
            isTheBrowser = true;
            actualVersion = jscriptMap[jscriptVersion];
        }

        ret = {
            isTheBrowser: isTheBrowser,
            actualVersion: actualVersion
        };

        return ret;
    }());

    // Check if IE 11
    if(!!window.MSInputMethodContext && !!document.documentMode) {
        IE.isTheBrowser = true;
    }


    if(IE.isTheBrowser) {
        $('img').each(function () {
            let $this = $(this);
            if($this.attr('data-srcset')) {
                let srcset = $this.data('srcset');
                let link = srcset.substring(srcset.lastIndexOf(",") + 1);
                link = link.substr(0, link.lastIndexOf(" ")).replace(/ /g,'');
                $this.attr('src',link);
            }
        });
        $('.swiper-lazy-preloader').remove();
    }
    return IE.isTheBrowser;
}

