(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.youtubeEmbeds = function(options) {
        let settings = $.extend({
            small: 800,
            medium: 1200
        }, options );

        let player;
        let counter = 1;
        let curPalyer;

        let tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        this.each(function () {
            let $this = $(this);
            let idmain = $this.data('id');
            //let idmedium = $this.data('id-medium');
            //let idsmall = $this.data('id-small');
            //let datasrc = $this.data('src');
            //let noAnimation = $this.data('noanim');
            let loop = $this.data('loop');
            let id = idmain;


            /*if(idsmall !== null && $(window).width() <= settings.small) {
                id = idsmall;
            } else if (idmedium !== null && $(window).width() <= settings.medium) {
                id = idmedium;
            } else {
                id = idmain;
            }*/

            let swiper = false;
            if ($this.hasClass('swiper-yt')) {
                swiper = true;
            }
            setThumb($this, id, swiper, loop);

        });

        function setThumb($this, id, swiper, loop) {
            setIframe($this, id, swiper, loop)


        }

        function clickPlay($this, id, swiper) {
            $this.find('.play').click(function () {
                setIframe($this, id, swiper);
            });
            $this.find('img').click(function () {
                setIframe($this, id, swiper);
            });


        }

        function setIframe($this, id, swiper, loop) {

            if (loop !== undefined && loop === true) {
                $this.html('<div class="closewrapper"><iframe id="player' + counter + '" src="https://player.vimeo.com/video/'+id+'?autoplay=1&loop=1&autopause=0&background=1&muted=1" frameborder="0" allowfullscreen="1"></iframe></div>');
            } else {
                $this.html('<div class="closewrapper"><iframe id="player' + counter + '" src="https://player.vimeo.com/video/' + id + '" frameborder="0" allowfullscreen="1"></iframe></div>');
                // with loaderanimation is not going because Youtube iframe API dont find data-src
                //$this.html('<iframe id="player'+counter+'" class="lazyload lazyloadinganimation" data-src="https://www.youtube.com/embed/'+id+'?enablejsapi=1" frameborder="0" allowfullscreen="1"></iframe>');

                /*let iframe = document.getElementById('player' + counter);

                let player;
                setTimeout(function () {
                    player = new YT.Player('player' + counter, {
                        events: {
                            'onReady': onPlayerReady,
                            'onStateChange': onPlayerStateChange
                        }
                    });
                    counter++;
                }, 50);


                function onPlayerReady(event) {
                    event.target.playVideo();
                }

                let play = false;
                let isfirstPlay = true;
                let isSmall = false;

                function onPlayerStateChange(event) {
                    if (event.data === YT.PlayerState.PLAYING) {
                        if (player !== curPalyer) {
                            if (curPalyer) {
                                curPalyer.stopVideo();
                                smallClose($('#' + curPalyer.a.id).parent())
                            }
                            curPalyer = player;
                        }
                        play = true;
                        if (isfirstPlay) {
                            let $id = $('#' + event.target.a.id).parent();
                            $(window).scroll(function () {
                                if (play) {
                                    if (!$id.parent().visible(true)) {
                                        if (!isSmall) {
                                            isSmall = true;
                                            $id.addClass('videofixed');
                                            setTimeout(function () {
                                                $id.addClass('trans');
                                                setTimeout(function () {
                                                    $id.addClass('show');
                                                }, 50);
                                            }, 310);

                                        }
                                    } else {
                                        if (isSmall) {
                                            smallClose($id);
                                            isSmall = false;
                                        }

                                    }

                                }
                            });
                            $id.find('.closebtn').click(function () {
                                smallClose($id);
                                player.stopVideo();
                                isSmall = false;
                            });

                        }
                    } else {
                        play = false;
                        isSmall = false;
                    }
                }

                function smallClose($id) {
                    $id.removeClass('show');
                    setTimeout(function () {
                        $id.removeClass('videofixed');
                        setTimeout(function () {
                            $id.removeClass('trans');
                        }, 50);
                    }, 310);
                }*/
            }
        }

        return this;
    };

}));