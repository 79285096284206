
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.accordionMenus = function(options) {

        let that = this;
        init();
        return this;

        function init(){
            that.each((index,item) =>{

                let content = $(item).find('.js-accordion-content');
                let head = $(item).find('.js-accordion-head');
                let plus = $(item).find('.js-accordion-plus');
                content.addClass('closed');
                head.on('click', function(e) {
                    onClickAccordionEvent(content,head,plus,e);
                }).find('.js-accordion-content').on('click',function (e){
                    //make content not close the accordion
                    e.stopPropagation();
                }).find('*').on('click',function (e){
                    //make content not close the accordion to be sure
                    e.stopPropagation();
                })

            })
        }
        function onClickAccordionEvent(content,head,plus,e){



            if(!content.hasClass('open')){
                content.outerHeight(content.children('div').outerHeight());
            }
            else{
                content.outerHeight(0);
            }
            plus.toggleClass('open');
            head.toggleClass('open');
            if(content.hasClass('open')){
                content.removeClass('open');
                content.addClass('closed');
            }
            else{
                content.addClass('open');
                content.removeClass('closed');
            }
        }
    };

}));
