(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.preloader = function(options) {
        let settings = $.extend({
            delylink: 1000,
            minTimeLoadingAnimation: 750,
            timeOpenPageAnimation: 1450,
            menuclosedely: 500,
            overlay: false,
            menu: true,
        }, options );

        // If overlay or not
        let plsvars =  $('.pls_wrapper');
        if (!plsvars.length) {
            plsvars = $('.preloaderwrapper');
        }
        // Parse SCSS Vars to JS
        if(plsvars.length) {
            let tester = plsvars.sassToJs({pseudoEl: '::before', cssProperty: 'content'});
            settings.delylink = tester.delylink;
            settings.minTimeLoadingAnimation = tester.minTimeLoadingAnimation;
            settings.timeOpenPageAnimation = tester.timeOpenPageAnimation;
            settings.menuclosedely = tester.menuclosedely;
            settings.overlay = tester.overlay;
            settings.delylink = tester.delylink;
            settings.showMenu = tester.showMenu;
        }

        let isNotTimeout = true;
        // if overlay
        if(settings.overlay) {

            // Click on link has not target="_blank"
            $('a:not([target="_blank"], .notransition, .cm-link)').click(function (e) {
                e.preventDefault();
                let $preloader = $('.pls_wrapper');
                // Get href
                let href = $(this).attr("href");
                if(settings.menu) {
                    if($('body').hasClass('mobile-menu-open')) {
                       $('button.hamburger').trigger('click');
                        isNotTimeout = false;
                        setTimeout(function(){
                            doPagetransition();
                        }, settings.menuclosedely);
                    }
                }

                if(isNotTimeout) {
                    doPagetransition();
                }

                function doPagetransition() {
                    if(settings.showMenu) {
                        //$("html, body").animate({ scrollTop: 0 }, 300);
                        let nav = $('nav');
                        let secmenu = $('.secmenu');
                        nav.addClass('pagetransition');
                        nav.addClass('zindex');
                        secmenu.removeClass('navbar-scroll');
                        nav.find('.wrapper > ul').removeClass('transition');
                    }
                    // Set preloader before page
                    $preloader.addClass('zindex');
                    // do pagetransition close
                    $preloader.addClass('show');
                    setTimeout(function(){
                        location.href = href;
                    }, settings.delylink);
                }


            });

            /*$('a.submenu').click(function (e) {
                e.preventDefault();
                let $preloader = $('.pls_wrapper');
                // Get href
                let href = $(this).attr("href");
                if(settings.menu) {
                    if(settings.menu.isOpen()) {
                        settings.menu.close();
                        isNotTimeout = false;
                        setTimeout(function(){
                            doPagetransition();
                        }, 750);
                    }
                }

                if(isNotTimeout) {
                    doPagetransition();
                }

                function doPagetransition() {
                    if(settings.showMenu) {
                        //$("html, body").animate({ scrollTop: 0 }, 300);
                        let nav = $('nav');
                        nav.addClass('zindex');
                        nav.find('.wrapper > ul').removeClass('transition');
                    }
                    // Set preloader before page
                    $preloader.addClass('zindex');
                    // do pagetransition close
                    $preloader.addClass('show');
                    setTimeout(function(){
                        location.href = href;
                    }, settings.delylink);
                }


            });*/

            setTimeout(function(){
                // Remove Preloaderanimation, do pagetransiton open
                let $preloader = $('.pls_wrapper').removeClass('show');
                $preloader.find('.pls_animation').removeClass('show');

                // Set preloader behind page

                setTimeout(function(){
                    $('.button').resizeSvgs();
                    $preloader.removeClass('zindex');
                    if(settings.showMenu) {
                        $('nav').removeClass('zindex');
                    }
                }, settings.timeOpenPageAnimation);
            }, settings.minTimeLoadingAnimation);
        } else {
            $('a:not([target="_blank"], .notransition)').click(function (e) {
                e.preventDefault();
                // Get href
                let href = $(this).attr("href");
                // Close Menu
                if(settings.menu) {
                    if(settings.menu.isOpen()) {
                        settings.menu.close();
                        isNotTimeout = false;
                        setTimeout(function(){
                            // do pagetransition close
                            doPagetransition();
                        }, settings.menuclosedely);
                    }
                }

                if(isNotTimeout) {
                    doPagetransition();
                }

                function doPagetransition() {
                    // do pagetransition close
                    if(settings.showMenu) {
                        $("html, body").animate({ scrollTop: 0 }, 300);
                        let nav = $('nav');
                        let secmenu = $('.secmenu');
                        nav.addClass('zindex');
                        nav.addClass('pagetransition');
                        secmenu.removeClass('navbar-scroll');
                        nav.find('.wrapper > ul').removeClass('transition');
                    }
                    $('.preloaderwrapper').addClass('fade-out');
                    setTimeout(function(){
                        location.href = href;
                    }, settings.delylink);
                }

            });
            setTimeout(function(){
                // Remove Preloaderanimation, do pagetransiton open
                $('.preloaderwrapper').removeClass('fade-in');
                if(settings.showMenu) {
                    setTimeout(function(){
                        $('nav').removeClass('zindex');
                    }, settings.timeOpenPageAnimation);
                }
            }, settings.minTimeLoadingAnimation);
        }


        return this;
    };

}));