(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.secmenu = function(options) {
        let settings = $.extend({
            icon: "icon-expand_more",
        }, options );

        let li = $(this).find('li');
        let a = $(this).find('li a');
        let liActive = $(this).find('li.active');
        let ul = $(this).find('ul');
        let arrowL = $(this).find('.arrowL');
        let arrowR = $(this).find('.arrowR');
        let gray = $(this).find('.gray');
        let black = $(this).find('.black');
        let curindex = liActive.index();
        let w = $( window ).width();
        let mc = new Hammer(document.querySelector(".secmenu ul"));


        // Set Desktop width LI
        li.width((100/li.length)+'%');


        function isDektop() {
            return $(window).width() >= 1200;
        }

        function isTablet() {
            return $(window).width() < 1200 && $(window).width() > 800;
        }

        function isMobile() {
            return $(window).width() <= 800;
        }


        // start
        start();
        function start() {
            if (isDektop()) {
                ul.css('width', '100%');
                ul.css('padding', '0 50px');
                ul.css('left', '0%');
            }
            if (isTablet()) {
                const width = ((li.length * 100) / 2);
                ul.css('width', width + '%');
                ul.css('padding', '0');
            }
            if (isMobile()) {
                ul.css('width', (li.length * 100) + '%');
                ul.css('padding', '0');
            }
            black.width((gray.width() - 50) / li.length);
            goTo(curindex);
            $('.secmenu').addClass('transition');

        }

        // Set Black
        function goTo(index) {

            toggleArrows(index, li.length-1);

            if (index === 0) {
                changeActiveClass(li, index);
                black.width(((gray.width() - 50) / li.length) + 25);
                black.css('left', '25px');
                sliderMobile(index);
            } else if (index === li.length-1) {
                changeActiveClass(li, index);
                const curLeft = black.css('left');
                const nextLeft = ((((gray.width() - 50) / li.length) * index) + 50) + 'px';
                black.width(((gray.width() - 50) / li.length) + 25);
                if (curLeft !== nextLeft) {
                    black.css('left', nextLeft);
                }
                sliderMobile(index);
            } else {
                changeActiveClass(li, index);
                const curLeft = black.css('left');
                const nextLeft = ((((gray.width() - 50) / li.length) * index) + 50) + 'px';
                if (curLeft !== nextLeft) {
                    black.width((gray.width() - 50) / li.length);
                    black.css('left', nextLeft);
                }
                sliderMobile(index);
            }

            function changeActiveClass(li, index) {
                li.removeClass('active');
                $(li.get(index)).addClass('active');
            }

            function sliderMobile(index) {
                if (isTablet()) {
                    const cur  = ul[0].style.left;
                    const next = -(index * 50) + '%';
                    if (cur !== next) {
                        ul.css('left', next);
                    }
                }

                if (isMobile()) {
                    const cur  = ul[0].style.left;
                    const next = -(index * 100) + '%';
                    if (cur !== next) {
                        ul.css('left', next);
                    }
                }
            }
        }

        arrowL.click(function() {
            console.log('Click Links');
            if (curindex > 0) {
                deactivateAllHandler();
                curindex--;
                goTo(curindex);
                $(li.get(curindex)).find('a').trigger("click");
            }
        });
        arrowR.click(function() {
            console.log('Click Right');
            if (curindex < li.length-1) {
                deactivateAllHandler();
                curindex++;
                goTo(curindex);
                $(li.get(curindex)).find('a').trigger("click");
            }
        });

        mc.on('swipeleft', function(ev) {
            if (curindex < li.length-1) {
                deactivateAllHandler();
                curindex++;
                goTo(curindex);
                $(li.get(curindex)).find('a').trigger("click");
            }
        });

        mc.on('swiperight', function(ev) {
            if (curindex > 0) {
                deactivateAllHandler();
                curindex--;
                goTo(curindex);
                $(li.get(curindex)).find('a').trigger("click");
            }
        });

        function deactivateAllHandler() {
            arrowL.off();
            arrowR.off();
            mc.destroy();
        }

        function toggleArrows(curindex, lastindex) {
            if (curindex === 0) {
                arrowR.removeClass('deactivate');
                arrowL.addClass('deactivate');
            } else if (curindex === lastindex) {
                arrowL.removeClass('deactivate');
                arrowR.addClass('deactivate');
            } else {
                arrowL.removeClass('deactivate');
                arrowR.removeClass('deactivate');
            }
        }



        a.click(function( event ) {
            event.preventDefault();
            goTo($(this).parents().index());
        });

        // Resize
        $( window ).resize( function() {
            // Resize Width
            if (w != $(window).width()) {
                start();
            }
            w = $( window ).width();
        });



        return this;
    };

}));