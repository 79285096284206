const rx = 10;

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.addSvgToButtons = function(options) {
        let that = this;
        init();
        return this;

        function init(){
            that.append(svg());
            that.addClass('hasSvg');
            setSvgs(that)
        }
    };

    $.fn.resizeSvgs = function(options) {

        let that = this;
        resizeSvg();
        return this;

        function resizeSvg(){
            let svg = that.find('svg');

            svg.unbind('mouseenter');
            svg.unbind(' mouseleave');
            setSvgs(that);
        }
        //this works dont ask how i figured this out

    };
}));
function setSvgs(elements){

    elements.each((index,item) =>{
        let jItem = $(item);
        let svg = jItem.find('svg');
        let values = getSvgValues(jItem);
        let rect = svg.find('rect');
        rect.css('stroke-dasharray',values.dashArrayStartX + ', ' + values.dashArrayStartY);
        rect.css('stroke-dashoffset',values.startDashOffset);
        rect.attr('rx',values.rx);
        svg.mouseenter(function (){
            jItem.css('color','#be7956');
            rect.css('stroke-dasharray',values.dashArrayHoverX + ', ' + values.dashArrayHoverY);
            rect.css('stroke-dashoffset',values.endDashOffset);
            rect.attr('stroke','#be7956');
        });
        svg.mouseleave(function (){
            jItem.css('color','#4A4A4A');
            rect.css('stroke-dasharray',values.dashArrayStartX + ', ' + values.dashArrayStartY);
            rect.css('stroke-dashoffset',values.startDashOffset);
            rect.attr('stroke','#4A4A4A');
        });


    })
}

function getSvgValues(button){
    const radiusMultiplier = 0.55;

    let obj = button.find('svg');
    let rect = obj.find('rect');
    //first get the height and width of the button

    //account for rect percentages
    let height = rect.outerHeight();
    let width = rect.outerWidth();

    //get the radius
    let radius = height * radiusMultiplier;


    //get half the circumference of the circle
    let circleHalf = Math.PI * radius//2* Math.PI * Math.sqrt((Math.pow(radius*2,2) + Math.pow(height,2))/2);


    //get the radius of the rect and remove it from the width because the dash array
    //starts after the radius only subtract it once to get the width from the origin point
    let heightWithoutRadius = height - (radius*2) ;//<= 0 ? 0 : height - (radius*2);
    let widthWithoutRadius = width - (radius*2);

    let pathLength = heightWithoutRadius*2 + widthWithoutRadius*2 + 2*circleHalf;

    //path along the box towards the left

    //margin to make the animation connect and not look to rough
    const animationMargin = width;
    //margin by what the bottom line gets pulled to the left
    const bottomAnimationMargin = widthWithoutRadius * 0.3;
    let dashArrayStartX = widthWithoutRadius + heightWithoutRadius + circleHalf + bottomAnimationMargin;
    let endDashOffset = circleHalf +heightWithoutRadius + widthWithoutRadius + animationMargin;
    let dashArrayHoverX =  widthWithoutRadius;
    let dashArrayHoverY = circleHalf + heightWithoutRadius + widthWithoutRadius + (endDashOffset - widthWithoutRadius);

    return{
        rx:height * radiusMultiplier,
        startDashOffset:0,
        dashArrayStartY:0,
        dashArrayStartX:dashArrayStartX,
        endDashOffset:endDashOffset,
        dashArrayHoverX:dashArrayHoverX,
        dashArrayHoverY:dashArrayHoverY
    };
}

function svg(){
    return  '<svg width="100%" height="100%">\n' +
        '     <rect x="0" y="0" fill="none" stroke="#4A4A4A" width="100%" height="100%" shape-rendering="geometricPrecision"></rect>\n' +
        '  </svg>'
}