
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.topMenu = function(options) {
        let settings = $.extend({
        }, options );

        let node = this;

        init();



        function init(settings){

            let children = node.children('li');

            children.each((index,child) =>{

                if(hasChildMenu($(child))){
                    bindMenuOpenOnClick($(child));
                }
            })
        }

        function hasChildMenu(n){

            let childMenu = n.find('.menu-point-child');
            return childMenu.length > 0;
        }

        function bindMenuOpenOnClick(n){
            let childMenu = n.find('.menu-point-child');

            let anchor = n.children('a');
            anchor.on('click',function(e) {
                e.preventDefault();
                openSubMenu(n,childMenu);
            });
        }

        function openSubMenu(n,childMenu){
            let list = n.parent();

            if(childMenu.hasClass('open')){

                toggleMenuPointChild(childMenu);
                n.removeClass('open');
                $(list).removeClass('menu-open');

            }
            else{
                closeAllMenus();

                toggleMenuPointChild(childMenu);
                n.addClass('open');
                $(list).addClass('menu-open');


            }
        }
        function closeAllMenus(){
            close();
        }

        return this;
    };
    $.fn.CloseAllMenus = function(options) {

        close();
        return this;
    }
    function close(){
        //get all sub menus
        let subs = $('.menu-top-list').children('li');


        subs.each((index,item) => {
            let jItem = $(item);

            //check if submenu
            if(jItem.find('div').length <= 0){

                return;
            }

            jItem.removeClass('open');
            let childMenu = jItem.find('.menu-point-child');
            let list = $(jItem.parent());


            childMenu.removeClass('open');
            childMenu.height(0);
            jItem.removeClass('open');
            list.removeClass('menu-open');
        });
    }
    function toggleMenuPointChild(menu){

        let x = window.matchMedia("(max-width: 1200px)")
        if(menu.hasClass('open')){
            menu.removeClass('open');
            if(x.matches){
                menu.height(0);
            }
        }
        else{
            menu.addClass('open');
            if(x.matches){
                menu.height(menu.children('ul').height());
            }
        }
        if(!x.matches){
            menu.removeAttr('style');
        }
    }
}));


function toggleMenuPoint(){
    let point = $('.menu-point');
    if(point.hasClass('open')){
        point.height(0);
        point.width(0);
        point.removeClass('open');
    }
    else {
        adjustMenuPointSize(point);
        point.addClass('open');
    }
}

function adjustMenuPointSize(point){
    let size = window.screen.height > window.screen.width ? window.screen.height : window.screen.width;
    //add the top/left in css
    point.height(size + 700);
    point.width(size + 700);
}