function initJS() {
    window.lazySizesConfig = window.lazySizesConfig || {};
//in case you want to use custom media query aliases in your markup, instead of full media queries
    window.lazySizesConfig.customMedia = {
        '--small': '(max-width: 799px)',
        '--medium': '(max-width: 1199px)'
    };
    window.lazySizesConfig.expand = -20;
    window.lazySizesConfig.loadMode = 1;

    $('.menu-top-list').topMenu();
    $('.js-accordion').accordionMenus();
    let nav = $('nav');
    nav.menuaccordion();


    $(document).on('lazybeforeunveil', function(e){
        if(!$(e.target).hasClass('swiper-container')) {
            $(e.target).parent().siblings('.animation').show();
            imageRatio.processImages($(e.target));
            $(e.target).on('load',function(e) {
                $(this).parent().siblings('.animation').hide();
                $(this).next().show();
                imageRatio.processImages($(this));
            });
        }
    });

    const $input = $('.form-fieldset > input');

    $input.blur(function (e) {
        $(this).toggleClass('filled', !!$(this).val());
    });


    //Preloader
    $('body').preloader();

    // Swiper
    $('.swiper-container').swiperInit();

    // Youtube
    $('.youtube-player').youtubeEmbeds();

    $('.referenzen').referenzen();
    $('.referenzenchefig').referenzenchefig();

    // Invert Menu
    if($(window).scrollTop() <= 20){
        nav.removeClass('navbar-scroll');
    } else {
        nav.addClass('navbar-scroll');
    }
    $(window).scroll(function(){
        if($(window).scrollTop() <= 20){
            nav.removeClass('navbar-scroll');
        } else {
            nav.addClass('navbar-scroll');
        }
    });


    function draggable404() {
        // Draggable 404
        const dragContainer = {
            width: $('.container.drag').width(),
            height: $('.container.drag').height(),
            element: $('.container.drag'),
        }
        let dragOptions = {
            width: 140,
            height: 200,
            multi: 200 / 140,
        }
        if (dragContainer.width <= 1600) {
            dragOptions = {
                width: 105,
                height: 150,
                multi: 150 / 105,
            }
        }
        if (dragContainer.width <= 700) {
            const widthnew = (dragContainer.width - 80) / 6
            dragOptions = {
                width: widthnew,
                height: widthnew * dragOptions.multi,
            }
        }

        $(".draggable.letter").css("width", dragOptions.width).css("height", dragOptions.height);

        const dragElements = {
            g: {
                element: $('.draggable.g'),
            },
            r: {
                element: $('.draggable.r'),
            },
            a: {
                element: $('.draggable.a'),
            },
            u: {
                element: $('.draggable.u'),
            },
            s: {
                element: $('.draggable.s'),
            },
            t: {
                element: $('.draggable.t'),
            },
            i: {
                element: $('.draggable.i'),
            },
            c: {
                element: $('.draggable.c'),
            },
            h: {
                element: $('.draggable.h'),
            },
        }




        // Calc left and top
        const widthAutsch = dragOptions.width * 6;
        const top = (dragContainer.height / 2) - (dragOptions.height / 2)

        dragElements.a.position = {
            left: (dragContainer.width / 2) - (widthAutsch / 2),
            top: top,
        }
        dragElements.u.position = {
            left: dragElements.a.position.left + dragOptions.width,
            top: top + 10,
        }
        dragElements.t.position = {
            left: dragElements.u.position.left + dragOptions.width,
            top: top ,
        }
        dragElements.s.position = {
            left: dragElements.t.position.left + dragOptions.width,
            top: top - 10,
        }
        dragElements.c.position = {
            left: dragElements.s.position.left + dragOptions.width,
            top: top,
        }
        dragElements.h.position = {
            left: dragElements.c.position.left + dragOptions.width,
            top: top + 15,
        }
        dragElements.i.position = {
            left: (dragContainer.width / 2) - (dragOptions.width / 2),
        }

        // Set left and top
        dragElements.a.element.css("left", dragElements.a.position.left).css("top", dragElements.a.position.top);
        dragElements.u.element.css("left", dragElements.u.position.left).css("top", dragElements.u.position.top);
        dragElements.t.element.css("left", dragElements.t.position.left).css("top", dragElements.t.position.top);
        dragElements.s.element.css("left", dragElements.s.position.left).css("top", dragElements.s.position.top);
        dragElements.c.element.css("left", dragElements.c.position.left).css("top", dragElements.c.position.top);
        dragElements.h.element.css("left", dragElements.h.position.left).css("top", dragElements.h.position.top);

        dragElements.g.element.css("left", 20).css("top", 30);
        dragElements.r.element.css("right", 20);
        dragElements.i.element.css("left", dragElements.i.position.left - 40).css("bottom", 20);
    }

    let $draggable = $('.draggable').draggabilly({
        containment: true,
    })
    draggable404();

    let w = 0;

    $(window).on("load", function () {
        w = $( window ).width();
        $('.button').addSvgToButtons();
    })
    $(document).ready(function (e){

    })
    $( window ).resize( function(){

        if( w !== $( window ).width() ){

            $draggable.draggabilly('destroy');
            $draggable = $('.draggable').draggabilly({
                containment: true,
            })
            draggable404();

            w = $( window ).width();
            $('.button').resizeSvgs();
        }

    });

}


window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'UA-1364477-4', { 'anonymize_ip': true });
