(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.referenzen = function(options) {
        let settings = $.extend({
            icon: "icon-expand_more",
        }, options );


        let first = true;
        let columns = getColumns();
        let sectionReferenzen = $(this);
        let sectionReferenzenHeight = 0;


        sectionReferenzen.find('.ref').each(function ( index, value ) {

            let nr = index +1;

            $(value).click(function () {

                if (sectionReferenzenHeight === 0) {
                    sectionReferenzenHeight = sectionReferenzen.outerHeight(true);
                    sectionReferenzen.height(sectionReferenzenHeight);
                }

                let $this = $(this);

                if (!$this.hasClass('active')) {

                    sectionReferenzen.find('.ref').removeClass('active');
                    $this.addClass('active');
                    $this.parent().parent().children( ".refinfo" ).css('opacity',0);
                    $this.parent().parent().children( ".cell" ).removeAttr('style');

                    let timer = 0;
                    if ($this.parent().parent().children( ".refinfo" ).length) {
                        timer = 500;
                    }

                    setTimeout(function(){

                        $this.parent().parent().children( ".refinfo" ).remove();

                        let row = Math.ceil(nr / columns);
                        $this.parent().parent().prepend( $this.next().html() );

                        setTimeout(function(){
                            let infoheight = $this.parent().parent().children( ".refinfo" ).outerHeight(true) + 100;

                            $this.parent().parent().children( ".refinfo" ).find('.swiper-container').swiperInit();
                            $this.parent().parent().children( ".refinfo" ).find('.youtube-player').youtubeEmbeds();

                            $($this.parent().parent().children( ".cell" ).get((row * columns) - 1)).nextAll().css('transform', 'translate(0,'+infoheight+'px)');
                            let infoTranslateY = ($this.outerHeight(true) + 50) * row;
                            $this.parent().parent().children( ".refinfo" ).css('transform', 'translate(-50%,'+infoTranslateY+'px)').css('opacity',1);
                            sectionReferenzen.css('height', sectionReferenzenHeight + infoheight + 'px');


                            let offset = 200;
                            if (first) {
                                offset = 350;
                                first = false;
                            }
                            $('html, body').animate({
                                scrollTop: $this.offset().top - offset
                            }, 500, function() {

                            });


                            $this.parent().parent().children( ".refinfo" ).find('.close').click(function () {
                                console.log('CLOSE');
                                let main = $(this).parent().parent().parent().parent();
                                close(main);
                            });

                        }, 100);


                    }, timer);
                } else {
                    close($this.parent().parent());
                }
            });
        });


        let lastWidth = $(window).width();

        $(window).resize(function(){
            if($(window).width()!==lastWidth){
                columns = getColumns();
                // close(sectionReferenzen.children().children());
                // sectionReferenzen.removeAttr('style');
                // sectionReferenzenHeight = 0;
                lastWidth = $(window).width();
            }
        });

        function getColumns() {
            let columns = 3;
            if ($(window).width() < 1200) {
                columns = 2
            }

            if ($(window).width() < 800) {
                columns = 1
            }

            return columns;
        }

        function close(main) {
            main.children( ".cell" ).removeAttr('style');
            main.children( ".refinfo" ).css('opacity',0);
            setTimeout(function(){
                main.children( ".refinfo" ).remove();
            }, 500);
            sectionReferenzen.css('height', sectionReferenzenHeight);
            sectionReferenzen.find('.ref').removeClass('active');
        }

        return this;
    };

}));
