(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.menuaccordion = function(options) {
        let settings = $.extend({
            icon: "icon-expand_more",
        }, options );

        // Var Declaration
        let canvasAnimation = false,
        canvasRight = false,
        hamburgerAnimation = false,
        onlyCanvas = false,
        buttonhamburger = $('button.hamburger'),
        html = $('html'),
        body = $('body'),
        main = $('main'),
        mainwrapper = $('.mainwrapper'),
        menuPoint = $('.menu-point'),
        h = $( window ).height(),
        w = $( window ).width(),
        $this = this;
        let firstUL = $this.find('.wrapper > .menu-wrapper');

        // Read AnimationTyp from CSS/SCSS
        let scssvars = $this.sassToJs({pseudoEl: '::before', cssProperty: 'content'});
        canvasRight = scssvars.ddmCanvasDirectionRight;
        canvasAnimation = scssvars.ddmCanvasAnimation;
        hamburgerAnimation = scssvars.hamburgerAnimation;
        onlyCanvas = scssvars.onlyCanvas;
        settings.mediaQuery = scssvars.ddmBreakmobile;

        settings.onlyCanvas = onlyCanvas;
        if(!canvasAnimation) {
            settings.offCanvas = false;
            settings.offCanvasAnimation = 'push';
        } else {
            settings.offCanvas = true;
            settings.offCanvasAnimation = canvasAnimation;
        }
        settings.canvasRight = canvasRight;
        settings.hamburgerAnimation = hamburgerAnimation;

        if(settings.hamburgerAnimation != false) {
            buttonhamburger.addClass('hamburger--'+settings.hamburgerAnimation)
        }
        if(settings.offCanvasAnimation == 'backgroundPushSide' || settings.offCanvasAnimation == 'backgroundPushTop' || settings.offCanvasAnimation == 'backgroundOverlay') {
            if(settings.onlyCanvas == 'true') {
                $this.before('<div class="menu-animation-wrapper"><div class="menu-animated-background"></div></div>')
            } else {
                $('nav > div > div > .wrapper > ul').before('<div class="menu-animation-wrapper"><div class="menu-animated-background"></div></div>')
            }

        }


        resizeHeight(settings, $this);
        init(settings, $this);

        // Resize
        $( window ).resize( function() {
            // Resize Width
            if (w != $(window).width()) {
                init(settings, $this);
            }
            w = $( window ).width();

            // Resize Height
            if (h != $(window).height()) {
                resizeHeight(settings, $this);
            }
            h = $( window ).height();
        });


        function init(settings, $this) {

            firstUL.removeClass('transition');
            if($( window ).width() <= settings.mediaQuery) {
                if(settings.offCanvasAnimation != 'push' && settings.offCanvas) {
                    removeOffCanvasAnimation(firstUL, settings.offCanvasAnimation);
                }
                if(!settings.offCanvas || settings.offCanvasAnimation == 'topToBottom') {
                    $this.addClass('onCanvas');
                }
                if(settings.offCanvasAnimation == 'backgroundPushSide' || settings.offCanvasAnimation == 'backgroundPushTop' || settings.offCanvasAnimation == 'backgroundOverlay') {
                    $('.menu-animated-background').removeClass('open');
                    $('.mainwrapper').addClass(settings.offCanvasAnimation)
                }


                buttonEvent($this, firstUL);
                setMenuHeight($this);
                //setArrows(settings, $this);

                //makeSubmenu($this);
                //makeMainmenu($this);
                if(settings.offCanvasAnimation != 'push' && settings.offCanvas) {
                    addOffCanvasAnimation(firstUL, settings.offCanvasAnimation);
                }

            } else {
                unsetMenuHeight($this);
            }
        }


        function makeMainmenu($this) {
            $this.find('li > ul').each(function () {
                let $this = $(this);
                if (!$this.hasClass('close')) {
                    makeAccordion($this, false);
                }
            });
        }


        function makeSubmenu($this) {
            $this.find('li > ul > li > ul').each(function () {
                makeAccordion($(this), true);
            });
        }


        function setArrows(settings, $this) {
            if (!$this.find('.arrow').length) {
                $this.find('li > ul').each(function () {
                    $(this).parent().prepend('<div class="arrow"><i class="' + settings.icon + '"></i></div>');
                });
            }
        }

        function addOffCanvasAnimation(firstUL, offCanvasClass) {
            if(offCanvasClass == 'rotatePusher') {
                mainwrapper.addClass(offCanvasClass);
                main.addClass(offCanvasClass);
            } else {
                firstUL.addClass(offCanvasClass);
                /* Nav has animation */
                if(offCanvasClass == 'rotateIn3d' || offCanvasClass == 'rotateOut3d' || offCanvasClass == 'scaleUp' || offCanvasClass == 'fallDown' || offCanvasClass == 'delayed3dRotate') {
                    mainwrapper.addClass(offCanvasClass);
                    $this.addClass(offCanvasClass);
                }

                /* Main has animation */
                if(offCanvasClass == 'scaleDownPusher' || offCanvasClass == 'scaleRotatePusher' || offCanvasClass == 'openDoor') {
                    main.addClass(offCanvasClass);
                    mainwrapper.addClass(offCanvasClass);
                }
            }
        }
        function removeOffCanvasAnimation(firstUL, offCanvasClass) {
            /* Nav has animation */
            if(offCanvasClass == 'rotateIn3d' || offCanvasClass == 'rotateOut3d' || offCanvasClass == 'scaleUp' || offCanvasClass ==  'fallDown' || offCanvasClass == 'delayed3dRotate') {
                mainwrapper.removeClass(offCanvasClass);
                $this.removeClass(offCanvasClass);
                firstUL.removeClass(offCanvasClass);
            }
        }


        function buttonEvent($this, firstUL) {
            buttonhamburger.removeClass('is-active');
            firstUL.removeClass('open');
            main.removeClass('open');
            body.removeClass('mobile-menu-open');
            html.removeClass('mobile-menu-open');
            menuPoint.removeClass('open');

            let offMenuWidth = firstUL.width();
            if(settings.offCanvas) {
                main.removeAttr('style');
            }
            buttonhamburger.unbind().click(function (event) {
                event.preventDefault();
                firstUL.addClass('transition');
                mainwrapper.addClass('transition');
                //$this.find('li > ul').addClass('close').height(0);
                //$this.find('.arrow').removeClass('open');
                //$('html,body').scrollTop(0);

                //wait for elements to fly out before closing so
                //get the last of the list wait for the animation to finish
                if(menuPoint.hasClass('open')){
                    let list = $('.menu-wrapper > .grid-container > .menu-list');
                    let lastChild = list.children('li').last();
                    $('.menu-top-list').CloseAllMenus();

                    list.addClass('flying-out');

                    let that = this;
                    lastChild.on("animationend webkitAnimationEnd oanimationend MSAnimationEnd", function waitForFlyout(e){
                        list.removeClass('flying-out');
                        buttonEventCloseFunction();
                        $(that).toggleClass('is-active').find('.hamburger-box').addClass('start');
                        lastChild.unbind("animationend webkitAnimationEnd oanimationend MSAnimationEnd",);
                    });
                }
                else
                {
                    $(this).toggleClass('is-active').find('.hamburger-box').addClass('start');
                    buttonEventCloseFunction();
                }


            })
        }

        function buttonEventCloseFunction(){
            menuPoint.toggleClass('open');
            firstUL.toggleClass('open');
            main.toggleClass('open');
            body.toggleClass('mobile-menu-open');
            html.toggleClass('mobile-menu-open');
            //close all menus
            $('.menu-top-list').CloseAllMenus();

            if(settings.offCanvas) {
                if(settings.offCanvasAnimation != 'scaleDownPusher' && settings.offCanvasAnimation != 'scaleRotatePusher' && settings.offCanvasAnimation != 'openDoor' && settings.offCanvasAnimation != 'topToBottom' && settings.offCanvasAnimation != 'backgroundPushSide' && settings.offCanvasAnimation != 'backgroundPushTop' && settings.offCanvasAnimation != 'backgroundOverlay') {
                    if(mainwrapper.is('[style]')) {
                        mainwrapper.removeAttr('style');
                    } else {
                        if(settings.canvasRight != false) {
                            mainwrapper.css('left',-Math.abs(offMenuWidth))
                        } else {
                            mainwrapper.css('left',offMenuWidth)
                        }

                    }
                }
            }

            if(settings.offCanvasAnimation == 'backgroundPushSide' || settings.offCanvasAnimation == 'backgroundPushTop' || settings.offCanvasAnimation == 'backgroundOverlay') {
                $('.menu-animated-background').toggleClass('open');
            }
        }

        function setMenuHeight0($this) {
            $this.find('.wrapper > .menu-wrapper').height(0);
        }


        function resizeHeight(settings, $this) {
            if($( window ).width() <= settings.mediaQuery) {
                setMenuHeight($this);
            }
        }


        function unsetMenuHeight($this) {
            $this.find('.wrapper > ul').height('auto');
            $this.find('.close').height('auto');
            $this.find('.wrapper > .menu-wrapper > .rastergradient').css("bottom","68px");
        }


        function setMenuHeight($this) {
            const menuHeight = $( window ).height() - $this.height() - 2 - $('.menu-contact-info-container').height();
            const li =  $this.find('.wrapper > .menu-wrapper > div > ul > li');
            let height = 0;
            li.each(function() {
                height += $(this).outerHeight(true);
            });
            $this.find('.wrapper > .menu-wrapper').height(menuHeight);

            const raster = $this.find('.wrapper > .menu-wrapper > .rastergradient');
            if ($( window ).width() <= 800) {
                raster.removeAttr('style');
                raster.css("top",height);
            } else if ($( window ).width() < 1200 && $( window ).width() > 800) {
                //const rasterGradientBottom = menuHeight + $this.height() + 2 - 230;
                raster.removeAttr('style');
                raster.css("top",height);
            } else {
                raster.removeAttr('style');
                raster.css("bottom","68px");
            }

        }


        function makeAccordion($this, submenu) {
            $this.height('auto');
            let height = $this.height();
            $this.height(0).addClass('close');
            $this.siblings('.arrow').removeClass('open').unbind().click(function (event) {
                event.preventDefault();
                let $this = $(this);
                if($this.hasClass('open')) {
                    $this.removeClass('open');
                } else {
                    $this.parent().parent().children('li').find('.arrow').removeClass('open');
                    $this.addClass('open');
                }
                let $thisUL = $(this).siblings('ul');
                let allULs = $thisUL.parent().parent().parent().parent().find('li > ul');
                let ulbefore = $thisUL.parent().parent();
                if($thisUL.hasClass('close')) {
                    if(!submenu) {
                        allULs.height(0);
                        allULs.addClass('close');
                    }

                    $thisUL.height(height);
                    $thisUL.removeClass('close');

                    if(submenu) {
                        let ulbeforeheight = ulbefore.height() + height;
                        ulbefore.height(ulbeforeheight);
                    }
                } else {
                    if(submenu) {
                        let ulbeforeheight = ulbefore.height() - height;
                        ulbefore.height(ulbeforeheight);
                    } else {
                        allULs.height(0);
                        allULs.addClass('close');
                        allULs.siblings('.arrow').removeClass('open');
                    }
                    $thisUL.height(0);
                    $thisUL.addClass('close');

                }

            })
        }

        this.close = function() {
            firstUL.addClass('transition');
            main.addClass('transition');
            $this.find('li > ul').addClass('close').height(0);
            $this.find('.arrow').removeClass('open');
            $('html,body').scrollTop(0);
            buttonhamburger.removeClass('is-active');
            firstUL.removeClass('open');
            main.removeClass('open');
            body.removeClass('mobile-menu-open');
            html.removeClass('mobile-menu-open');
            //close all menus
            $('.menu-top-list').CloseAllMenus();

            let offMenuWidth = firstUL.width();

            if(settings.offCanvas) {
                if(settings.offCanvasAnimation != 'scaleDownPusher' && settings.offCanvasAnimation != 'scaleRotatePusher' && settings.offCanvasAnimation != 'openDoor' && settings.offCanvasAnimation != 'topToBottom' && settings.offCanvasAnimation != 'backgroundPushSide' && settings.offCanvasAnimation != 'backgroundPushTop' && settings.offCanvasAnimation != 'backgroundOverlay') {
                    if(mainwrapper.is('[style]')) {
                        mainwrapper.removeAttr('style');
                    } else {
                        if(settings.canvasRight != 'false') {
                            mainwrapper.css('left',-Math.abs(offMenuWidth))
                        } else {
                            mainwrapper.css('left',offMenuWidth)
                        }

                    }
                }
            }

            if(settings.offCanvasAnimation == 'backgroundPushSide' || settings.offCanvasAnimation == 'backgroundPushTop' || settings.offCanvasAnimation == 'backgroundOverlay') {
                $('.menu-animated-background').removeClass('open');
            }
        };

        this.isOpen = function() {
            return !!body.hasClass('mobile-menu-open');
        };

        return this;
    };

}));