(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.referenzenchefig = function(options) {
        let settings = $.extend({
            icon: "icon-expand_more",
        }, options );


        let sectionReferenzen = $(this);
        let currentSize = "";
        const data = sectionReferenzen.data();
        let lastWidth = $(window).width();

        if (data) {
            setBg(lastWidth);
        }


        $(window).resize(function(){
            if($(window).width()!==lastWidth){
                if (data) {
                    lastWidth = $(window).width();
                    setBg(lastWidth);
                }
            }
        });

        function setBg(width) {
            if (width >= 1200) {
                if (currentSize !== 'large' && data.bgimgLarge) {
                    sectionReferenzen.css("background-image", "url("+data.bgimgLarge+")");
                    currentSize = 'large';
                }

            } else if (width < 1200 && width >= 800) {
                if (currentSize !== 'medium' && data.bgimgMedium) {
                    sectionReferenzen.css("background-image", "url("+data.bgimgMedium+")");
                    currentSize = 'medium';
                }

            } else {
                if (currentSize !== 'small' && data.bgimgSmall) {
                    sectionReferenzen.css("background-image", "url("+data.bgimgSmall+")");
                    currentSize = 'small';
                }

            }
        }

        return this;
    };

}));