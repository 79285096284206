(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module depending on jQuery.
        define(['jquery'], factory);
    } else {
        // No AMD. Register plugin with global jQuery object.
        factory(jQuery);
    }
}(function ($) {

    $.fn.swiperInit = function(options) {
        let settings = $.extend({
            direction: 'horizontal',
            loop: true,
            autoplay: 15000,
            grabCursor: true,
            lazyLoading: true,
            lazyLoadingOnTransitionStart: true,
            lazyLoadingInPrevNext: true,
            nolazy: false,

            // If we need pagination
            pagination: '.swiper-pagination',

            // Navigation arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',

            // And if we need scrollbar
            //scrollbar: '.swiper-scrollbar',
            onInit: function (swiper) {
                $('.swiper-yt').youtubeEmbeds();
                if($(window).width() < 640) {
                    let $swipercontainer = $(swiper.container);
                    let minheight = parseInt($swipercontainer.css('min-height').replace('px',''));
                    let heighest = 0;
                    $swipercontainer.find('.figure-outer').each(function () {
                        let thisheight = $(this).height();
                        if(thisheight > heighest) {
                            heighest = thisheight;
                        }
                    });
                    $swipercontainer.css('min-height',minheight+heighest);
                    $swipercontainer.find('img').css('min-height',minheight);
                }
            }
        }, options );

        let $this = $(this);
        let hasStarted = false;


        $(document).on('lazybeforeunveil', function(e){
            if(!hasStarted && $this.length) {
                let swiperclass = $this.attr('class').split(' ')[0];
                if($(e.target).hasClass(swiperclass)) {
                    hasStarted = true;
                    let mySwiper = new Swiper ('.'+swiperclass, settings);

                }
            }
        });

        if(!settings.nolazy) {
            $this.each(function () {
                let $this = $(this);
                let curWidth = $this.width();
                let data = $this.find('.swiper-slide').find('img').first().data();
                let vpw = $(window).width();
                let breakpoint = null;
                Object.keys(window.lazySizesConfig.customMedia).map(function(objectKey, index) {
                    let value = window.lazySizesConfig.customMedia[objectKey];
                    if (parseInt(value.match(/\d/g).join("")) >= vpw && breakpoint == null) {
                        breakpoint = objectKey.replace('--','');
                    }
                });
                if(breakpoint === null) {
                    breakpoint="Large";
                } else {
                    breakpoint = breakpoint.capitalizeFirstLetter();
                }
                if ('aspectratio'+breakpoint in data) {
                    let sizes = data['aspectratio'+breakpoint].split('/');
                    let height = curWidth / (sizes[0] / sizes[1]);
                    $this.css('min-height',height+'px');
                }
            });
        }


        if($this.visible( true )) {
            if(!hasStarted) {
                let swiperclass = $this.attr('class').split(' ')[0];
                hasStarted = true;
                let mySwiper = new Swiper ('.'+swiperclass, settings);
            }
        }


        return this;
    };

}));